.Header {
    background: #fff;
}

.header {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    height         : 64px;
    font-size      : 14px;
    line-height    : 20px;
    color          : #333;
    background     : #fff;

    a {
        color: #333;
    }

    .home {
        cursor: pointer;
    }

    .options {
        position   : relative;
        display    : flex;
        align-items: center;
        height     : 100%;


        .cartIcon {
            display        : inline-block;
            width          : 22px;
            height         : 22px;
            margin-right   : 12px;
            background     : url('./image/cart.png') no-repeat;
            background-size: 100% 100%;
        }

        .item {
            display        : flex;
            justify-content: center;
            align-items    : center;
            min-width      : 2em;
            height         : 100%;
            margin-left    : 25px;
            text-align     : right;
            cursor         : pointer;
        }

        .nickname {
            position   : relative;
            display    : flex;
            align-items: center;
            height     : 100%;
            color      : #007BDD;

            &::after {
                content        : '';
                right          : 0;
                width          : 14px;
                height         : 7px;
                margin-left    : 11px;
                background     : url('./image/xj.png') no-repeat;
                background-size: 100% 100%;
                transition     : transform 0.3s;
            }
        }

        .open::after {
            content  : '';
            transform: rotate(-180deg);
        }

        .nicknameBox {
            position     : absolute;
            top          : 65px;
            right        : -51px;
            overflow     : hidden;
            z-index      : 11;
            display      : block;
            // width     : 283px;
            height       : 0px;
            padding      : 0 40px;
            background   : #FFFFFF;
            text-align   : center;
            box-shadow   : 0px 8px 20px 0px rgba(51, 51, 51, 0.15);
            transition   : height 0.3s, padding 0.3s, opacity 0.3s;
            opacity      : 0;

            &.isEn {
                .nicknameBoxName {
                    margin-left: 20px;
                }

                .nicknameBoxItem {
                    justify-content: flex-start;
                }
            }
        }

        .nicknameBoxShow {
            display: block;
            height : 250px;
            padding: 54px 40px 30px;
            opacity: 1;
        }

        .nicknameBoxItem {
            display        : flex;
            justify-content: space-between;
            align-items    : center;

            img {
                width        : 56px;
                height       : 56px;
                border       : 2px solid #DFDFDF;
                border-radius: 50%;
            }

            .nicknameBoxName {
                height       : 22px;
                padding      : 0 9px;
                text-align   : center;
                font-size    : 12px;
                color        : #666666;
                line-height  : 22px;
                background   : #F2F2F2;
                border-radius: 11px;
            }
        }

        .growth {
            white-space: nowrap;
            margin-top : 18px;
            font-size  : 14px;
            color      : #73322C;
            line-height: 20px;

            &+.growth {
                margin-left: 30px;
            }
        }

        .nicknameBtn {
            // width        : 100%;
            display      : inline-block;
            height       : 40px;
            margin-top   : 30px;
            padding      : 0 40px;
            text-align   : center;
            font-size    : 14px;
            color        : #FFFFFF;
            line-height  : 40px;
            background   : linear-gradient(270deg, #00A5EC 0%, #19C4FF 100%);
            border-radius: 20px;
        }

        .offLogin {
            position: absolute;
            right   : 30px;
            top     : 20px;
            width   : 15px;
            height  : 16px;
        }

        .hover {
            color: #007BDD;
        }

        .appBox {
            position       : absolute;
            top            : 64px;
            right          : 0;
            overflow       : hidden;
            z-index        : 11;
            display        : flex;
            justify-content: space-between;
            display        : flex;
            width          : 360px;
            height         : 0;
            padding        : 0 30px;
            background     : #FFFFFF;
            box-shadow     : 0px 8px 20px 0px rgba(51, 51, 51, 0.15);
            transition     : height 0.2s, padding 0.2s, opacity 0.2s;
            opacity        : 0;

            &.isEn {
                width: 450px;
            }
        }

        .appBoxShow {
            height : 220px;
            padding: 30px;
            opacity: 1;
        }

        .appItem {
            flex          : 1;
            display       : flex;
            flex-direction: column;
            align-items   : center;

            &+.appItem {
                margin-left: 20px;
            }

            .qrcode {
                width        : 120px;
                height       : 120px;
                margin-bottom: 13px;
                object-fit   : contain;
            }

            span {
                font-size  : 12px;
                color      : #333333;
                line-height: 17px;
            }
        }

        .login {
            height       : 30px;
            padding      : 0 11px;
            text-align   : center;
            font-size    : 13px;
            color        : #FFFFFF;
            line-height  : 30px;
            background   : linear-gradient(270deg, #00A5EC 0%, #19C4FF 100%);
            border-radius: 15px;
            cursor       : pointer;
        }
    }
}

.top {
    padding   : 25px 0;
    border-top: 1px solid #F1F3F4;
    background: #fff;

    .affixSpace {
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        background     : #fff;
        padding-top    : 13px;
        padding-bottom : 13px;

        .logoImg {
            width : 200px;
            cursor: pointer;
        }

        .search {
            position       : relative;
            display        : flex;
            justify-content: space-between;
            align-items    : center;
            width          : 418px;
            height         : 38px;
            padding-left   : 45px;
            background     : #FFFFFF;
            border-radius  : 20px;
            border         : 1px solid #02B5FC;

            // border-image : linear-gradient(270deg, rgba(2, 181, 252, 1), rgba(0, 183, 255, 1)) 1 1;
            &::before {
                content        : '';
                position       : absolute;
                left           : 20px;
                width          : 16px;
                height         : 16px;
                background     : url('./image/search.png') no-repeat;
                background-size: 100% 100%;
            }

            .searchBtn {
                width        : 88px;
                height       : 38px;
                margin-left  : 20px;
                border       : none;
                border-radius: 0 20px 20px 0;
                text-align   : center;
                font-size    : 14px;
                color        : #FFFFFF;
                line-height  : 38px;
                background   : linear-gradient(270deg, #00A5EC 0%, #19C4FF 100%);
            }
        }

        .searchInput {
            border: 0;
        }
    }

    .navList {
        margin-top: 20px;
        text-align: left;

        .cup {
            cursor: pointer;
        }
    }

    .coupons {
        position      : absolute;
        left          : 20px;
        top           : 48px;
        font-size     : 12px;
        // color      : #999999;
        color         : #00B7FF;
        line-height   : 17px;
        cursor        : pointer;
    }
}