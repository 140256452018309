:global {
    .MyModal {
        .ant-modal {
            padding: 0;

        }

        .ant-modal-content {
            border-radius: 12px;

            .ant-modal-body {
                padding: 0 !important;
            }
        }
    }
}