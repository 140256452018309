.modal {
    position: relative;
    width   : 100%;
    padding : 21px 30px 25px;

    .resetTitle {
        margin-bottom: 24px;
        text-align   : center;
        font-size    : 16px;
        font-weight  : 700;
        color        : #333333;
        line-height  : 22px;
    }

    .resetInput {
        display    : flex;
        align-items: center;
        margin-top : 15px;

        .resetInputName {
            width      : 74px;
            font-size  : 15px;
            font-weight: 300;
            color      : #333333;
            line-height: 21px;
        }

        .resetInputBox {
            display        : flex;
            justify-content: space-between;
            align-items    : center;
            flex           : 1;
            width          : 100%;
            height         : 48px;
            background     : #F6F6F6;
            border-radius  : 5px;
        }

        .resetCode {
            margin-right: 15px;
            font-size   : 14px;
            font-family : PingFangSC-Light, PingFang SC;
            font-weight : 300;
            color       : #999;
            line-height : 20px;
            color       : #007BDD;
        }

        .resetCodes {
            color : #007BDD;
            cursor: pointer;
        }
    }

    .code {
        display       : flex;
        flex-direction: column;
        align-items   : center;

        .codeTitle {
            margin-top : 25px;
            font-size  : 16px;
            color      : #333333;
            line-height: 22px;
        }

        .codeText {
            margin     : 20px 0 10px;
            font-size  : 12px;
            color      : #666666;
            line-height: 20px;
            z-index    : 1;

            span {
                color: #007BDD;
            }
        }
    }

    .nav {
        display        : flex;
        justify-content: center;
        margin-bottom  : 26px;
        font-size      : 14px;
        line-height    : 32px;

        .text {
            height    : 32px;
            padding   : 0 18px;
            text-align: center;
            color     : #333333;
            cursor    : pointer;
        }

        .active {
            color        : #FFFFFF;
            background   : linear-gradient(270deg, #00A5EC 0%, #19C4FF 100%);
            border-radius: 16px;
        }
    }

    .tipsText {
        text-align: center;
        margin-top: 5px;
        color     : #999;
    }

    .check {
        display        : flex;
        justify-content: space-between;
        flex-wrap      : wrap;
        align-items    : center;
        margin-top     : 22px;
        font-size      : 13px;
        font-weight    : 300;
        color          : #666666;
        line-height    : 18px;

        .checkPas {
            cursor: pointer;
            color : #007BDD;
        }

        .checkTxt {
            color: #007BDD;
        }
    }

    .btn {
        width        : 100%;
        height       : 44px;
        margin       : 30px 0 25px;
        text-align   : center;
        font-size    : 16px;
        color        : #FFFFFF;
        line-height  : 44px;
        border-radius: 22px;
        cursor       : pointer;
        background   : linear-gradient(270deg, #02B5FC 0%, #00B7FF 100%);

        &.disbaled {
            background: #DDDDDD;
        }
    }

    .loginType {
        display        : flex;
        justify-content: center;
        margin-bottom  : 7px;
        font-size      : 14px;
        font-weight    : 300;
        color          : #666666;
        line-height    : 20px;

        .phoneLogin {
            margin-right: 30px;
            cursor      : pointer;
        }

        .wxLogin {
            cursor: pointer;
        }
    }

    .codeLogin {
        position: absolute;
        right   : 0;
        bottom  : 0;
        width   : 60px;
        height  : 60px;
        cursor  : pointer;
    }

    .resetBtn {
        margin: 30px 0 0;
    }
}

.labelWrapper {
    display    : flex;
    align-items: center;

    &+.labelWrapper {
        margin-top: 15px;
    }

    .inputWrapper {
        width: 230px;
    }

    .labelName {
        min-width: 5em;
    }
}

.labelTips {
    margin-bottom: 15px;
    padding-left : 70px;
    font-size    : 12px;
    color        : #e74c3c;
}

.inputWrapper {
    position       : relative;
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    width          : 100%;
    height         : 48px;
    padding        : 0 15px;
    background     : #F6F6F6;
    border-radius  : 5px;

    &+.inputWrapper {
        margin-top: 15px;
        font-size : 15px;
        text-align: left;
        color     : #333;
    }

    .input {
        border      : 0;
        padding-left: 0;
        background  : transparent;
    }

    .hidePwd {
        width          : 20px;
        height         : 20px;
        background     : url('../image/pwdLook.png') no-repeat center;
        background-size: 100%;
        cursor         : pointer;

        &.show {
            background-image: url('../image/pwdNoLook.png');
        }
    }

    .getCaptcha {
        width      : 5em;
        text-align : center;
        white-space: nowrap;
        color      : #00B7FF;
        cursor     : pointer;
    }
}

.codeWrapper {
    position  : relative;
    width     : 165px;
    height    : 165px;
    margin-top: 10px;
    font-size : 16px;

    .codeImg {
        width : 165px !important;
        height: 165px !important;
    }

    .invalid {
        margin-bottom: 15px;
    }

    .waiting {
        position       : absolute;
        top            : 0;
        right          : 0;
        bottom         : 0;
        left           : 0;
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: center;
        color          : #fff;
        background     : rgba(0, 0, 0, 0.7);
    }
}