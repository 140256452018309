.box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 310px;
    height: 84px;
    margin: 10px 15px;
    padding: 15px 0;
    border-radius: 4px;
    background: linear-gradient(270deg, #FF9B85 0%, #EF7476 100%);
}

.boxLeft {
    display: flex;

    .price {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80px;
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 700;
        line-height: 40px;

        span {
            font-size: 26px;
        }

        .txt {
            font-size: 11px;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 12px;
        color: #FFFFFF;

        .name {
            transform: scale(0.9);
            transform-origin: left top;
        }

        .time {
            white-space: nowrap;
            transform: scale(0.8);
            transform-origin: left;
        }

        .explain {
            display: flex;
            align-items: center;
            cursor: pointer;
            white-space: nowrap;
            transform: scale(0.8);
            transform-origin: left bottom;

            &::after {
                content: '';
                width: 9px;
                height: 9px;
                margin-left: 4px;
                background: url('./image/xj.png') no-repeat;
                background-size: 100% 100%;
            }
        }

        .explains {
            &::after {
                transform: rotate(180deg);
            }
        }
    }
}

.received {
    width: 67px;
    height: 24px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    text-align: center;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 22px;
}

.receive {
    width: 67px;
    height: 24px;
    margin-right: 10px;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    color: #DB7072;
    line-height: 24px;
    background: #FFFFFF;
    cursor: pointer;
}

.note {
    width: 310px;
    padding: 12px 10px;
    margin: -12px 15px 0;
    border-radius: 0px 0px 4px 4px;
    background: #F8F8F8;
}

.notUsed {
    width: 60px;
    height: 60px;
    margin-right: 12px;
}