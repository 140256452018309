.Upload {
    display: flex;

    :global {

        .ant-upload-select,
        .ant-upload-select-picture-card {
            border: 0 !important;
        }
    }

    .uploadIconWrapper {
        position: relative;

        .uploadIconText {
            position      : absolute;
            top           : 75px;
            left          : 0;
            display       : flex;
            flex-direction: column;
            align-items   : center;
            width         : 100%;
            font-size     : 12px;
            color         : #aaa;
            transform     : translateY(-50%);
        }
    }
}

.uploadImg {
    width : 100%;
    height: 100%;
}