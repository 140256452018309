.Goods {
    width        : 232px;
    height       : 332px;
    background   : #FFFFFF;
    border-radius: 10px 10px 8px 8px;
    cursor       : pointer;

    &:hover .img {
        transform: scale(1.16);
    }

    .cover {
        overflow: hidden;
        width   : 100%;
        height  : 232px;
    }

    .img {
        width     : 100%;
        height    : 232px;
        transition: transform 0.2s;
    }

    .name {
        // width      : calc(100% - 20px);
        height     : 40px;
        margin     : 9px 0 16px;
        padding    : 0 10px;
        text-align : left;
        font-size  : 14px;
        font-weight: 400;
        color      : #333333;
        line-height: 20px;
    }

    .isFull {
        display        : inline-flex;
        justify-content: center;
        align-items    : center;
        height         : 16px;
        margin-right   : 6px;
        padding        : 0 5px;
        border-radius  : 2px;
        font-size      : 11px;
        line-height    : 1;
        color          : #fff;
        background     : #FF8236;
    }

    .info {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        padding        : 0 10px;

        .price {
            font-size  : 16px;
            font-weight: 700;
            color      : #F01520;
            line-height: 22px;
        }

        .sales {
            font-size  : 12px;
            color      : #999999;
            line-height: 17px;
        }
    }
}