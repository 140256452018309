.footer {
    margin-top : 50px;
    font-size  : 14px;
    line-height: 20px;
    color      : rgba(255, 255, 255, 0.8);
    background : #007BDD;

    .friends {
        display      : flex;
        align-items  : center;
        flex-wrap    : wrap;
        padding      : 20px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        .friend {
            flex-basis     : 25%;
            padding        : 10px 0;
            text-align     : left;
            color          : rgba(255, 255, 255, 0.8);
            text-decoration: none;
            cursor         : pointer;
        }
    }

    .copyright {
        padding: 25px 0;
    }
}

.sites {
    display       : flex;
    margin-top    : 20px;
    padding-bottom: 20px;
    border-bottom : 1px solid rgba(255, 255, 255, 0.2);

    .siteTitle {
        font-weight  : 700;
        text-align   : left;
        margin-bottom: 15px;
    }

    .site {
        display      : flex;
        align-items  : center;
        height       : 24px;
        margin-bottom: 12px;
        color        : rgba(255, 255, 255, 0.8);
    }

    .icon {
        width          : 24px;
        height         : 24px;
        margin-right   : 10px;
        background     : url('./image/facebook.png') no-repeat center;
        background-size: 100%;
    }

    .siteBox {
        &+.siteBox {
            margin-left: 80px;
        }
    }
}