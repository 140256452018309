.Addition {
    display    : flex;
    align-items: center;

    .input {
        width      : 42px;
        height     : 26px;
        margin     : 0 5px;
        line-height: 26px;
        text-align : center;
    }

    .opt {
        display        : inline-flex;
        align-items    : center;
        justify-content: center;
        width          : 18px;
        height         : 26px;
        border         : 1px solid #ccc;
        border-radius  : 2px;
        cursor         : pointer;

        &.disabled {
            background: #ddd;
            cursor    : not-allowed;
        }
    }

    :global {
        .ant-input-number-handler-wrap {
            display: none;
        }

        .ant-input-number-input {
            height     : 26px;
            padding    : 0;
            line-height: 26px;
            text-align : center;
        }
    }
}