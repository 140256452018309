.pages {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}

.select {
    display: flex;
    align-items: center;
    width: 100px;
    height: 44px;
    padding: 6px 0 !important;
    border-radius: 5px;
    font-size: 13px !important;
    color: #333333 !important;
    background: #F6F6F6;

}

.option {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #333333 !important;
    line-height: 18px;
    background: #F5F6F9 !important;

    &::before {
        content: '';
        position: absolute;
        right: 16px;
        width: 15px;
        height: 15px;
        background: url('../../common/helps/image/optionActive.png') no-repeat;
        background-size: 100% 100%;
    }
}