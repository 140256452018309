.pagination {
    display        : flex;
    justify-content: center;
    align-items    : center;
    color          : #999;

    .page {
        margin: 0 20px;
    }

    .input {
        width        : 54px;
        height       : 30px;
        margin       : 0 10px;
        text-align   : center;
        background   : #FFFFFF;
        border-radius: 2px;
        border       : 1px solid #CCCCCC;
    }

    .btn {
        // width        : 61px;
        height       : 30px;
        margin-left  : 20px;
        padding      : 0 12px;
        border       : none;
        text-align   : center;
        font-size    : 14px;
        font-weight  : 700;
        color        : #FFFFFF;
        line-height  : 30px;
        background   : linear-gradient(270deg, #00A5EC 0%, #19C4FF 100%);
        border-radius: 2px;
    }
}