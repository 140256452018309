.Coupon {
    position     : relative;
    display      : flex;
    align-items  : stretch;
    width        : 310px;
    height       : 84px;
    padding      : 12px;
    padding-left : 22px;
    border-radius: 5px;
    color        : #fff;
    background   : linear-gradient(to left, #FF9B85 0%, #EF7476 100%);

    &.EXPIRED {
        background: linear-gradient(to left, #DDDDDD 0%, #CCCCCC 100%);
    }

    .fund {
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: center;

        .fundNum {
            line-height   : 1;
            vertical-align: baseline;
        }

        .yuan {
            font-size  : 20px;
            font-weight: 700;
        }

        .unit {
            font-size: 12px;
        }

        .type {
            margin-top : 5px;
            font-size  : 11px;
            line-height: 16px;
        }
    }

    .info {
        flex           : 1;
        display        : flex;
        flex-direction : column;
        justify-content: space-around;
        align-items    : flex-start;
        margin-left    : 15px;
        font-size      : 10px;
        line-height    : 14px;

        .rule {
            font-size  : 11px;
            line-height: 16px;
        }

        .desc {
            cursor: pointer;
        }

        .descIcon {
            transition: transform 0.3s;

            &.up {
                transform: rotate(-180deg);
            }
        }
    }

    .icon {
        display        : none;
        flex-shrink    : 0;
        width          : 60px;
        height         : 60px;
        background     : url('./images/used.png') no-repeat center;
        background-size: contain;
    }

    .icons(@cls, @name) {
        &.@{cls} .icon {
            display         : inline-block;
            background-image: url('./images/@{name}.png');

            &.isEn {
                background-image: url('./images/@{name}-en.png');
            }
        }
    }

    .icons(UNUSED, unused);
    .icons(EXPIRED, expired);
    .icons(USED, used);

    .description {
        position     : absolute;
        bottom       : 5px;
        left         : 0;
        z-index      : 1;
        width        : 100%;
        padding      : 0 10px;
        border-radius: 0 0 5px 5px;
        font-size    : 11px;
        line-height  : 16px;
        text-align   : left;
        color        : #999;
        background   : #f8f8f8;
        transform    : translateY(100%);

        .descText {
            display: inline-block;
            padding: 12px 0;
        }
    }
}