.GoodsCard {
    display    : flex;
    align-items: center;
    font-size  : 14px;
    line-height: 20px;
    color      : #333;
    cursor     : pointer;

    .cover {
        overflow     : hidden;
        display      : inline-flex;
        align-items  : center;
        width        : 102px;
        height       : 102px;
        border-radius: 10px;
        background   : #f6f6f6;
    }

    .coverImg {
        width: 100%;
    }

    .info {
        flex           : 1;
        display        : flex;
        flex-direction : column;
        justify-content: center;
        margin-left    : 15px;
        text-align     : left;

        .line {
            display        : flex;
            justify-content: space-between;
            margin-top     : 10px;
        }
    }
}