#root {
    display       : flex;
    flex-direction: column;
    text-align    : center;
    min-height    : 100vh;
    background    : #F7F9FA;

}

.App-content {
    flex: 1;
}


.Container {
    width              : 1200px;
    margin             : 0 auto;
    // padding         : 0 calc(50vw - 600px);
    box-sizing         : content-box !important;

    .ant-empty-normal {
        width: 100%;
    }
}

.hide {
    display: none;
}

input {
    flex   : 1;
    border : none;
    outline: 0;
}

button {
    border : 0;
    outline: none;
}

.iconfont {
    font-size  : 12px;
    line-height: 8px;
    color      : #ccc;

    &.blue {
        color: #007BDD;
    }
}