.Button {
    display        : inline-flex;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    height         : 44px;
    padding        : 0;
    border-radius  : 22px;
    border         : 1px solid transparent;
    font-size      : 14px;
    color          : #fff;
    cursor         : pointer;

    &.common {
        border-color: #979797;
        color       : #666;
        background  : #fff;
    }

    .gradient(@name, @color1, @color2) {
        &.@{name} {
            background-image: linear-gradient(to right, @color1, @color2);
        }
    }

    .gradient(orange, #F7D882, #F79626);
    .gradient(red, #F12300, #FF4B16);
    .gradient(blue, #00B7FF, #02B5FC);

    .pureBtn(@name, @color, @bgc) {
        &.@{name} {
            border-color: currentColor;
            color       : @color;
            background  : @bgc;
        }
    }

    .pureBtn(white, #45A6FF, #fff);
    .pureBtn(black, #333, #fff);
    .pureBtn(pure, #fff, transparent);

    &.disabled {
        &.blue {
            background: #45A6FF;
        }
    }

    .children:empty {
        display: none;
    }
}