.total {
    width          : 88px;
    margin         : 0 5px;
    display        : flex;
    align-items    : center;
    align-content  : center;
    justify-content: center;

    button {
        width          : 18px;
        height         : 26px;
        background     : #ffffff;
        border-radius  : 2px;
        border         : 1px solid #cccccc;
        color          : #666666;
        cursor         : pointer;
        display        : flex;
        align-items    : center;
        align-content  : center;
        justify-content: center;

        &:disabled {
            cursor          : not-allowed;
            background-color: #dddddd;
        }
    }

    input {
        cursor        : pointer;
        width         : 42px;
        height        : 26px;
        margin        : 0 5px;
        font-size     : 13px;
        font-family   : PingFangSC-Regular, PingFang SC;
        font-weight   : 400;
        color         : #666666;
        line-height   : 18px;
        background    : #ffffff;
        border-radius : 2px;
        border        : 1px solid #cccccc;
        text-align    : center;
        padding       : 0px;
        vertical-align: middle;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &[type="number"] {
            -moz-appearance: textfield;
        }
    }
}